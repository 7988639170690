import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { InlineShareButtons } from 'sharethis-reactjs';
// import FacebookShare from '../FacebookShare';
// import Facebook from '../../images/facebook-share-btn.gif';

const AudioPlayer = (props) => {
    const [messages, setMessage] = useState([]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');

    useEffect(() => {
        fetch("https://www.gatewayjubilee.com/api/message/?id=" + id)
            .then((response) => response.json())
            .then((data) => {
                setMessage(data);
            })
    }, [id]);

    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const formatDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date(date + "T00:00:00");
        return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    }

    return (
        <>
            <div>
                    <div className="single-message-info" key={messages.id}>
                        <div className="single-title center-text" id="long">{messages.title}</div>
                        <div className="single-date center-text">{formatDate(messages.msgdate)}</div>
                        <div className="single-speaker center-text">{messages.speaker}</div>

                        <audio controls autoPlay src={"https://gatewayjubilee.com" + messages.link} id="audio-player"></audio>
                        <Link to="/messages" className="btn btn-large btn-dark-blue center margin-top-20" >BROWSE WEEKLY MESSAGES</Link>
                        <Link to="/jubilee" className="btn btn-large btn-dark-blue center margin-top-20" >BROWSE JUBILEE MESSAGES</Link>
                    </div>
            </div>

        </>
    );
}

export default AudioPlayer;
